@import '~font-awesome/css/font-awesome.css';

table, td, th {
    border: 1px solid black;
    text-align:center;
    vertical-align:middle;
    border-collapse: collapse;
    width: 100%;
}

.big-wrapper {
    padding-bottom: 20px;
}

.no-border {
    border: none;
}

body {
    font-family: "Courier New";
    font-size: 15px;
    color: #8e8e8e;
    min-height: 100%;
    background: #f9f9f9;
      -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.full-width-off, .body-wrap {
    max-width: 1100px;
    margin: auto auto 10em;
    position: relative;
    // -webkit-box-shadow: 0px 0px 2px rgba(99, 99, 99, .2);
    // box-shadow: 0px 0px 2px rgba(99, 99, 99, .2);
}
@media screen and (max-width: 1365px) {
    .body-wrap {max-width:80%}
}
@media screen and (max-width: 750px) {
    .body-wrap {max-width:600px}
}


.center_text {
    width: 100%;
    text-align: center;
}

.overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f9f9f9;
    // background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    text-align:center;
    overflow:scroll;
}
// .overlay img {
//     // min-height: 100%;
//     // min-width: 100%;
//     width: 100%;
//     height: auto;
// }

a {
    text-decoration: none;
    cursor: pointer;
}
